<template>
<v-container style="display: grid; justify-content: center; align-content: center; margin: auto">
  <v-card flat class="ma-auto pa-0">
    <v-alert
      outlined
      prominent
      border="top"
      color="warning">
      <div class="alert-container">
        <v-icon
          color="warning"
          class="alert-icon mx-auto mt-2 mb-3">
            mdi-alert-outline
        </v-icon>
        <div class="text-h6">
          Temporarily Down for Maintenance
        </div>
        <v-divider
          class="my-4 mx-auto warning"
          style="opacity: 0.22; width: 100%; max-width: 80%">
        </v-divider>
        <v-row align="center">
          <v-col class="grow">
            Service is unavailable at this time due to scheduled website maintenance.<br>
            Please check back later.<br><br>
            We apologize for the inconvenience.
          </v-col>
        </v-row>
      </div>
    </v-alert>
  </v-card>
</v-container>
</template>
<script>
export default {
  name: 'Maintenance'
}
</script>
<style scoped>
.alert-container {
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 10px 25px;
}

.alert-icon {
  background-color: #FFF3E0;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 16px;
}
</style>